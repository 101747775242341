import React from 'react';
import { useTranslation } from 'next-i18next';
import ReduxWrapper from '@services/store';
import ssrProvider from '@services/ssrProvider';
import ErrorTemplate from '@features/ui-layout/templates/ErrorTemplate';

const Description404 = (t) => (
  <div>
    {t('404_descriptionNotFound.message')}
    <br />
    {t('404_descriptionBrokenLink.message')}
  </div>
);

const Custom404 = () => {
  const { t } = useTranslation();
  return (
    <ErrorTemplate
      headTitle={`${t('head_404Title.message')} ${t('head_siteTitle.message')}`}
      headDescription={t('head_404Description.message')}
      title={t('404_title.message')}
      descriptionTitle={t('404_descriptionTitle.message')}
      description={Description404(t)}
    />
  );
};

export default Custom404;

export const getStaticProps = ReduxWrapper.getServerSideProps(
  (store) => async (serverSideContext) => ssrProvider({
    store,
    serverSideContext,
    withLogin: false,
  }),
);
