import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import config, { FEATURES_SECTIONS } from '@root/src/config';
import { mediaBreakpoint } from '@services/responsiveProvider';
import getRoute from '@services/routeList';
import { textH1, textH3, textP } from '@features/ui/styles/textStyles';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';

interface ErrorTemplateProps {
  headTitle: string,
  headDescription: string,
  title: string,
  descriptionTitle: string,
  description: React.ReactNode,
}

const ErrorTemplate = ({ headTitle, headDescription, title, description, descriptionTitle }: ErrorTemplateProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{headTitle}</title>
        <meta name="description" content={headDescription} />
      </Head>
      <div>
        <TitleError>{title}</TitleError>
        <ErrorImageContainer />
        <ErrorDescription>
          <ErrorDescriptionTitle>{descriptionTitle}</ErrorDescriptionTitle>
          {description}
          <ErrorDescriptionsOptions>
            <Button
              text={t('errorTemplate_goToEvents.message')}
              url={getRoute.home()}
              leftIcon={ICON_NAME.arrowForward}
            />
            {!config.featuresFlags[FEATURES_SECTIONS.PDG_HARDCODE].enabled && (
              <>
                <Button
                  text={t('errorTemplate_goToFaq.message')}
                  url={getRoute.faq()}
                  leftIcon={ICON_NAME.helpCircle}
                  variant={BUTTON_VARIANT.secondary}
                />
              </>
            )}
          </ErrorDescriptionsOptions>
        </ErrorDescription>

      </div>
    </>
  );
};

export default ErrorTemplate;

const TitleError = styled.h1`
  ${textH1}
  margin-top: 8px;
`;

const ErrorImageContainer = styled.div`
  height: 500px;
  background-image: url('/images/mso-error-404.jpg');
  background-size: cover;
  margin-top:52px;
  background-position: center; 
  ${mediaBreakpoint.tablet} {
    height: 350px;
  }
  ${mediaBreakpoint.mobile} {
    height: 200px;
  }
`;

const ErrorDescription = styled.div`
  ${textP}
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ErrorDescriptionTitle = styled.h3`
  ${textH3}
  margin-bottom: 8px;
`;

const ErrorDescriptionsOptions = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  a+a{
    margin-left: 12px;
  }
  margin-bottom: 60px;
  ${mediaBreakpoint.tablet} {
    flex-direction: column;
    a+a{
      margin-top: 12px;
    } 
  }
`;
